"use client";

import { useCartStore } from "@/store/useCartStore";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import { StripeElementsOptionsMode, loadStripe } from "@stripe/stripe-js";
import { useMemo } from "react";
import CheckoutForm from "./checkoutForm";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { useParams } from "next/navigation";




function StripeCheckout() {
  const { cart, totalPrice } = useCartStore();
  const { org } = useParams();

  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm 
          stripe={stripe} 
          elements={elements} 
          cart={cart}
          totalPrice={totalPrice}
          org={org as string}
        />
      )}
    </ElementsConsumer>
  );
}

export default StripeCheckout;
