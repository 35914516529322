"use client";
import React, { useCallback, useState } from 'react';
import StripeCheckout from "@/components/checkoutForm/elementsProvider";
import { useCartStore } from "@/store/useCartStore";
import CartItem from "../cartSidebar/cartItem/cartItem";
import { useWeb3Authentication } from "@/context/web3AuthContext";
import { toast } from "react-toastify";
import RegistrationModal from "@/components/registrationModal/RegistrationModal";
import { useAssetsStore } from "@/store/assetsStore";
import { Button } from "../ui/button";
import Link from 'next/link';
import { Rubik } from "next/font/google";
import { Elements } from "@stripe/react-stripe-js";
const rubik = Rubik({ subsets: ["latin"], style: ["normal", "italic"] });
import { loadStripe } from "@stripe/stripe-js";
import { useMemo } from "react";
import { StripeElementsOptionsMode } from "@stripe/stripe-js";
interface CheckoutProps {
  branding: { eventId: string } | undefined;
  isSidebar?: boolean;
  onClose?: () => void;
}

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!);

const Checkout: React.FC<CheckoutProps> = ({ branding, isSidebar, onClose }) => {
  const { login, userData, addToAttendeesList } = useWeb3Authentication();
  const cartStore = useCartStore();
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const [error, setError] = useState<boolean | string>(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  const eventAssetsStore = useAssetsStore();

  const handleLogin = useCallback(async () => {
    try {
      if (userData) {
        if (!userData.registered) {
          setShowRegistrationModal(true);
        }
      } else {
        const newUserData = await login();
        if (newUserData && !newUserData.registered) {
          setShowRegistrationModal(true);
        }
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  }, [userData, login]);

  const collectibleAttendeesList = async (collectibles: any[]) => {
    try {
      await Promise.all(collectibles.map(async (collectible) => {
        const response = await addToAttendeesList(collectible.event_id, collectible.id, "collectible");
        if (response.status === 200 || response.status === 300) {
          toast.success(response.message, {
            position: "bottom-right",
            autoClose: 2000,
          });
        } else {
          toast.error(response.message, {
            position: "bottom-right",
            autoClose: 2000,
          });
        }
      }));
      toast.success("Collectible checkout completed successfully!", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      console.error('Add to attendees list failed:', error);
      toast.error("Collectible checkout failed. Please try again.");
      setError(true);
    }
  }

  const ticketAttendeesList = async (tickets: any[]) => {
    try {
      await Promise.all(tickets.map(async (ticket) => {
        const response = await addToAttendeesList(ticket.event_id, ticket.id, "ticket");
        if (response.status === 200 || response.status === 300) {
          toast.success(response.message, {
            position: "bottom-right",
            autoClose: 2000,
          });
        } else {
          toast.error(response.message, {
            position: "bottom-right",
            autoClose: 2000,
          });
        }
      }));
      toast.success("Ticket checkout completed successfully!", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } catch (error) {
      console.error('Add tickets to attendees list failed:', error);
      toast.error("Ticket checkout failed. Please try again.", {
        position: "bottom-right",
        autoClose: 2000,
      });
      setError(true);
    }
  }

  const options: StripeElementsOptionsMode = useMemo(
    () => ({
      mode: "payment",
      amount: cartStore.totalPrice,
      currency: "usd",
      appearance: {
        theme: "night",
      },
    }),
    [cartStore.totalPrice]
  );

  const handleCheckout = () => {
    setIsProcessing(true);
    setError(false);
    console.log("Processing items");
    Promise.all([
      collectibleAttendeesList(cartStore.cart.filter((item) => item.type === "collectible")),
      ticketAttendeesList(cartStore.cart.filter((item) => item.type === "ticket"))
    ]).then(() => {
      cartStore.clearCart(); 
      setCheckoutSuccess(true);
    })
    .catch((err) => {
      setError(true);
      console.error(err);
    })
    .finally(() => {
      setIsProcessing(false);
    });
  };

  const isPaymentRequired = cartStore.totalPrice > 0;

  if (checkoutSuccess) {
    return (
      <Link href="/my-tickets">
        <Button
          className="text-white text-xl cursor-pointer underline bg-[#2bb673] hover:bg-[#f5f5f5] hover:text-[#2bb673]"
          onClick={cartStore.toggleCart}
        >
          View collectibles and tickets
        </Button>
      </Link>
    );
  }

  return (
    <div className={`w-full ${rubik.className}`}>
      {userData ? (
        isPaymentRequired ? (
          <div className="text-gray-800 dark:text-white text-xl mb-6">
            <h3 className="text-xl font-bold mb-4">Payment Information Required</h3>
            <p className="mb-4">Please provide your payment details to complete the checkout process.</p>
            <Elements stripe={stripePromise} options={options}>
              <StripeCheckout/>
            </Elements>
          </div>
        ) : (
            cartStore.cart.length > 0 ? (
            <Button
              onClick={handleCheckout}
              disabled={isProcessing}
              className={`bg-[#2bb673] hover:bg-[#f5f5f5] hover:text-[#2bb673] font-bold text-white rounded-lg p-2 w-full ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isProcessing ? 'Processing...' : 'Complete Checkout'}
            </Button>
          ) : (
            <div className="text-gray-800 dark:text-white text-xl">Your cart is empty</div>
          )
        )
      ) : (
        <div className="flex flex-col gap-4">
          <div className="text-gray-800 dark:text-white text-xl">Please login to checkout</div>
          <Button onClick={handleLogin} variant="primary" className="bold">
            Log In
          </Button>
        </div>
      )}
      {error && <p className="text-red-500 mt-2">{error}</p>}
      {userData && !userData.registered && (
        <RegistrationModal 
          isOpen={showRegistrationModal} 
          onClose={() => setShowRegistrationModal(false)} 
        />
      )}
    </div>
  );
};



export default Checkout;