"use client";

import TicketShell from "@/components/assetShell/ticketShell";
import { useCartStore } from "@/store/useCartStore";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useCallback } from "react";
import CartItemIncreaseDecrease from "../cartItemIncreaseDecrease";
import CollectibleShell from "@/components/assetShell/collectibleShell";

const CartItem = ({
  id,
  tierId,
  name,
  quantity,
  price,
  description,
  type,
  orgId,
}: {
  id: string;
  tierId?: string
  name: string;
  quantity: number;
  price: number;
  description: string;
  type: "product" | "ticket" | "collectible";
  orgId: string;
}) => {
  const removeQuantity = useCartStore((state) => state.decrementQuantity);

  const cartItem = useCartStore((state) => state.cart).find(
    (item) => item.id === id
  );
  const convertCentsToDollars = (priceInCents: number): number => {
    return parseFloat((priceInCents / 100.0).toFixed(2));
  };
  const addToCart = useCallback(() => {
    useCartStore.getState().addToCart({
      name: name,
      price: price,
      id: id,
      description: description,
      quantity: 1,
      type: type,
      organization_id: orgId
    });
  }, []);
  return (
    <div className="flex gap-4 bg-neutral-700 rounded px-2 pt-2 h-auto min-h-[105px] overflow-hidden faded-bottom text-left">
      <div className="flex-shrink-0 w-[80px] relative">
        {type == "ticket" ? (
          <TicketShell ticketId={id} size="small" padding={"small"} />
        ) : (
          <CollectibleShell collectibleId={id} size="small" padding={"small"} orgId={orgId} />
        )}
        <div className="w-full h-full absolute top-0 max-h-[97px] faded-bottom z-50"></div>
      </div>
      <div className="flex flex-col w-full pb-2">
        <div className="flex justify-end mb-2">
          <CartItemIncreaseDecrease
            value={cartItem?.quantity || 0}
            increase={() => addToCart()}
            decrease={() => removeQuantity(id)}
          />
        </div>
        <div className="text-white font-bold mb-2">{name}</div>
        <div className="text-white font-bold md:hidden mb-1">
          Price: <span className="font-bold text-green-300 ml-1">${convertCentsToDollars(price)}</span>
        </div>
        <div className="text-neutral-400 text-xs md:text-sm mb-2 break-words">{description}</div>
        <div className="text-white font-bold hidden md:block mt-auto">
          Price: <span className="font-bold text-green-300 ml-1">${convertCentsToDollars(price)}</span>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
