"use client";

import { createContext, useContext, useEffect, useRef, useState } from "react";

interface NotificationsContext {
  notifications: INotificationTypeV1[];
  clearNotification: (id: string) => void;
}

const initialState: NotificationsContext = {
  notifications: [],
  clearNotification: () => {},
};

const NotificationsContext = createContext<NotificationsContext>(initialState);

const NotificationsProvider = ({ children }: any) => {
  const [notifications, setNotifications] = useState<INotificationTypeV1[]>([]);
  const [messageQ, setMessageQ] = useState<INotificationTypeV1[]>([]);

  const openConnection = useRef(false);

  const clearNotification = (id: string) => {};

  //   const notificationsConnection = fetch(
  //     "http://localhost:3000/api/v1/notifications"
  //   );

  // useEffect(() => {
  //   let active = true;
  //   if (openConnection.current) return;

  //   const eventSource =
  //     active && new EventSource("/api/v1/notifications");

  //   eventSource.onmessage = (event) => {
  //   //   const data = JSON.parse(event);
  //     console.log(JSON.parse(event.data));
  //     //active && setNotifications((notifications) => [...notifications, data]);
  //   };

  //   openConnection.current = true;
  //   console.log("notifcations stream is running");

  //   window.addEventListener("beforeunload", function (e) {
  //     eventSource.close()//Gecko + IE                           //Webkit, Safari, Chrome
  //   });

  //   return () => {
  //     active = false;
  //   };
  // }, []);

  return (
    <NotificationsContext.Provider value={{ notifications, clearNotification }}>
      {children}
    </NotificationsContext.Provider>
  );
};

const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error(
      "useNotifications must be used within a NotificationsProvider"
    );
  }
  return context;
};

export { NotificationsProvider, useNotifications };
