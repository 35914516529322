"use client";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { getAuthHeadersAndBody } from "@/helpers/web3auth/verifyToken";
import { useWeb3Authentication } from "@/context/web3AuthContext";
import { Rubik } from "next/font/google"; // Import the Rubik font
import Image from "next/image";

const rubik = Rubik({ subsets: ["latin"], style: ["normal", "italic"] }); // Define the Rubik font

export type Inputs = {
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
  image_url: string;
};

const RegistrationForm = ({ onClose }: { onClose: () => void }) => {
  const {  registerUser } = useWeb3Authentication();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try{
      const response = await registerUser(data);
      // console.log(response);
      if (response.status == 200) {
        toast.success(response.message,
          {
            position: "bottom-right",
            autoClose: 2000,
          }
        );
        onClose();
      } else {
        toast.error(response.message,
          {
            position: "bottom-right",
            autoClose: 2000,
          }
        );
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
  };

  return (
    <div className={`max-w-[600px] border-2 border-white rounded-2xl p-8 bg-gray-800 ${rubik.className}`}>
      <form className="gap-2 flex flex-col justify-center" onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="image_url" className="flex justify-center pb-12">
          {imagePreviewUrl ? (
            <Image
              alt="Profile Image"
              src={imagePreviewUrl}
              className="w-[150px] aspect-square bg-gray-500 rounded-xl object-cover"
              width={150}
              height={150}
            />
          ) : (
            <div className="w-[150px] aspect-square bg-gray-900 rounded-xl text-white flex justify-center items-center font-bold underline">
              Profile Image
            </div>
          )}
        </label>
        <input
          {...register("image_url")}
          onChange={(e) =>
            e.target.files ? setImagePreviewUrl(URL.createObjectURL(e.target.files[0])) : null
          }
          type="file"
          id="image_url"
          style={{ height: 0, padding: 0, margin: 0, border: "none" }}
          className="text-[#181818]"
        />
        <label htmlFor="first_name" className="pt-4">
          First Name
        </label>
        <input {...register("first_name", { required: true })} className="text-gray-800 px-2 rounded-xl" />
        {errors.first_name && <span>This field is required</span>}
        <label htmlFor="last_name" className="">
          Last Name
        </label>
        <input {...register("last_name")} className="text-gray-800 px-2 rounded-xl"/>
        <label htmlFor="email_address" className="">
          Email Address
        </label>
        <input {...register("email_address", { required: true })} className="text-gray-800 px-2 rounded-xl"/>
        {errors.email_address && <span>This field is required</span>}
        <label htmlFor="phone_number" className="">
          Phone Number
        </label>
        <input {...register("phone_number")} className="text-gray-800 px-2 rounded-xl"/>
        <input
            className="cursor-pointer py-2 mt-4 rounded-md bg-transparent hover:bg-white hover:text-gray-800 text-white text-xl font-bold"
          type="submit"
          onClick={() => onClose()}
        />
      </form>
    </div>
  );
};

export default RegistrationForm;