import { AWS_BASE_URL_ORGS } from "@/helpers/constants";
import Image from "next/image";

const CollectibleShell = ({
  collectibleId,
  size,
  padding,
  hover,
  imageUrl,
  glow, // Add a new prop for the glow effect
  orgId,
}: {
    collectibleId: string;
  size: "small" | "medium" | "large" | "auto";
  padding: "small" | "medium" | "large";
  hover?: boolean;
  imageUrl?: string; // Add imageUrl as a prop
  glow?: boolean; // Add glow as a prop
  orgId: string;
}) => {
  const sizePixels = () => {
    switch (size) {
      case "small":
        return "80px";
      case "medium":
        return "125px";
      case "large":
        return "300px";
      case "auto":
        return "auto";
    }
  };

  const paddingSize = () => {
    switch (padding) {
      case "small":
        return "p-0";
      case "medium":
        return "p-2";
      case "large":
        return "p-2";
    }
  };
  console.log(AWS_BASE_URL_ORGS + orgId);
  return (
    <div
      className={`aspect-[9/16] overflow-hidden h-full  bg-transparent rounded ${paddingSize()} relative flex items-center justify-center`}
      style={{ width: sizePixels() }}
    >
      <Image
        alt="Collectible"
        src={AWS_BASE_URL_ORGS + orgId}
        className="h-4/5 w-auto object-cover rounded-lg border-2 border-white"
        height={200}
        width={200}
      />
    </div>
  );
};

export default CollectibleShell;
