import React from 'react';
import { useWeb3Authentication } from '@/context/web3AuthContext';
import { AuthButton } from './authButton';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface ProfilePopupProps {
  onClose: () => void;
}

export const ProfilePopup: React.FC<ProfilePopupProps> = ({ onClose }) => {
  const { userData } = useWeb3Authentication();

  if (!userData) return null;

  const hasCustomImage = userData.customUserData.image_url !== "[No Image]";

  return (
    <div className="absolute right-0 mt-2 bg-black rounded-lg shadow-xl z-50 min-w-max">
      <div className="p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-white transition-colors duration-200"
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
        <div className="flex items-center space-x-4 mb-4">
          {hasCustomImage ? (
            <Image
              src={userData.customUserData.image_url}
              alt="Profile"
              className="w-16 h-16 rounded-full"
              width={100}
              height={100}
            />
          ) : (
            <div className="w-16 h-16 rounded-full bg-gray-700 flex items-center justify-center">
              <FontAwesomeIcon icon={faUser} className="text-white text-3xl" />
            </div>
          )}
          <div className="flex flex-col space-y-1">
            <h3 className="font-semibold text-white text-lg">
              {userData.customUserData.first_name} {userData.customUserData.last_name}
            </h3>
            <p className="text-sm text-green-400 px-2 py-1 bg-green-900 rounded-full inline-block">
              {userData.customUserData.email_address}
            </p>
          </div>
        </div>
        <div className="border-t border-gray-700 pt-4 mt-4">
          <AuthButton />
        </div>
      </div>
    </div>
  );
};