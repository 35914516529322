// components/RegistrationModal.tsx
import React from 'react';
import RegistrationForm, { Inputs  } from '@/components/forms/userRegForm';
import { Rubik } from "next/font/google"; // Import the Rubik font
import { useState } from 'react';

const rubik = Rubik({ subsets: ["latin"], style: ["normal", "italic"] }); // Define the Rubik font


type RegistrationModalProps = {
  isOpen: boolean;
  onClose: () => void;

};

const RegistrationModal: React.FC<RegistrationModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-transparent rounded-lg p-8">
         <RegistrationForm onClose={onClose} />
      </div>
    </div>
  );
};

export default RegistrationModal;