import React from 'react';
import { useWeb3Authentication } from '@/context/web3AuthContext';
import { Button } from '@/components/ui/button';

export const AuthButton: React.FC = () => {
  const { login, logout, isLoading, userData } = useWeb3Authentication();

  const handleAuth = async () => {
    if (userData) {
      await logout();
    } else {
      await login();
    }
  };

  return (
    <Button
      onClick={handleAuth}
      disabled={isLoading}
      variant="primary"
      className={`w-full ${userData ? 'bg-green-500 hover:bg-green-800' : 'bg-green-500 hover:bg-green-600'}`}
    >
      {isLoading ? 'Processing...' : userData ? 'Sign Out' : 'Sign In'}
    </Button>
  );
};