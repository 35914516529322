import React, { useState } from 'react';
import Image from 'next/image';
import { useWeb3Authentication } from '@/context/web3AuthContext';
import { ProfilePopup } from './profile';
import { AuthButton } from './authButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export const NavbarProfileButton: React.FC = () => {
  const { userData } = useWeb3Authentication();
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => setShowPopup(!showPopup);

  if (!userData) {
    return <AuthButton />;
  }

  const hasCustomImage = userData.customUserData.image_url !== "[No Image]";

  return (
    <div className="relative">
      <button
        className="flex items-center space-x-2 p-2 rounded-full hover:bg-gray-700 transition-colors duration-200"
        onClick={togglePopup}
      >
        {hasCustomImage ? (
          <Image
            src={userData.customUserData.image_url}
            alt="Profile"
            width={32}
            height={32}
            className="rounded-full"
          />
        ) : (
          <div className="w-8 h-8 rounded-full bg-gray-700 flex items-center justify-center">
            <FontAwesomeIcon icon={faUser} className="text-white text-lg" />
          </div>
        )}
        <span className="text-sm font-medium text-white">
          {userData.customUserData.first_name}
        </span>
      </button>
      {showPopup && <ProfilePopup onClose={togglePopup} />}
    </div>
  );
};