"use client";

import { useCartStore } from "@/store/useCartStore";
import { ShoppingCartIcon as Icon } from "@heroicons/react/24/solid";
import PopupCart from "../popup-cart";

const ShoppingCartIcon = () => {
  const { totalItems, toggleCart, cartOpen } = useCartStore();

  return (
    <div
      className="relative z-60"
      onClick={toggleCart}
    >
      <Icon className="text-white w-6 md:w-7 cursor-pointer" />
      {totalItems > 0 && (
        <div className="bg-green-700 text-xs select-none text-white absolute -top-1 -right-1 md:bottom-0 md:right-0 p-1.5 rounded-full h-5 w-5 flex justify-center items-center cursor-pointer">
          {totalItems}
        </div>
      )}
      {cartOpen && <PopupCart />}
    </div>
  );
};

export default ShoppingCartIcon;
