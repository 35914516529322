"use client";
import { useCartStore } from "@/store/useCartStore";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ButtonComponent from "../buttons/Button";
import CartItem from "./cartItem/cartItem";
import { useParams } from "next/navigation";
import { Rubik } from "next/font/google";
import Checkout from "@/components/checkout/checkout";
import { useCallback, useState } from "react";
import { useWeb3Authentication } from "@/context/web3AuthContext";
import RegistrationModal from "@/components/registrationModal/RegistrationModal";
import { Button } from "../ui/button";
import { useRouter } from "next/navigation";

const rubik = Rubik({ subsets: ["latin"], style: ["normal", "italic"] });

type Branding = {
  eventId: string;
};

const PopupCart = () => {
  const { cart, toggleCart, totalPrice, cartOpen } = useCartStore();
  const router = useRouter();

  const [error, setError] = useState(false);

  const numberFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const convertCentsToDollars = (priceInCents: number): number => {
    return parseFloat((priceInCents / 100.0).toFixed(2));
  };

  const eventId = "";
  return (
    <>
      {/* Background overlay */}
      {cartOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
          onClick={toggleCart}
        ></div>
      )}

      {/* Sidebar for desktop, bottom slide for mobile */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`fixed bg-neutral-800 transform transition-transform duration-300 ease-in-out z-50 shadow-lg border border-neutral-700 ${rubik.className} ${
          cartOpen ? "translate-x-0 md:translate-y-0" : "translate-x-full md:translate-y-full"
        } 
        w-full  rounded bottom-0 md:w-full md:max-w-md md:top-0 md:right-0 right-0 md:h-full md:max-h-full md:overflow-y-auto`}
      >
        <div className="flex justify-between items-center mb-2 p-4">
          <div className="text-2xl text-white">Cart</div>
          <XMarkIcon
            onClick={(e) => {
              e.stopPropagation();
              toggleCart();
            }}
            className="w-8 h-8 cursor-pointer text-neutral-400"
          />
        </div>
        <hr className="border-bottom border-neutral-700" />
        {cart.length > 0 ? (
          <div className="flex flex-col gap-3 max-h-[calc(100vh-200px)] overflow-y-auto p-4">
            {cart.map((item) => (
              <CartItem
                key={item.id}
                id={item.id}
                name={item.name}
                quantity={item.quantity}
                price={item.price}
                description={item.description}
                type={item.type}
                orgId={item.organization_id || ""}
              />
            ))}
          </div>
        ) : (
          <div className="text-center text-neutral-400 py-6">
            Go find some tickets!
          </div>
        )}
        <hr className="border-bottom border-neutral-700" />
        <div className="flex items-center justify-between mt-2 p-4">
          <div className="flex flex-col">
            <div className="text-tGreen font-bold text-lg">
              Total: ${numberFormatter.format(convertCentsToDollars(totalPrice))}
            </div>
          </div>
        </div>
        <hr className="border-bottom border-neutral-700" />
        <div className="flex justify-center p-4">
        {
          totalPrice > 0 ? (
              <div className="text-white text-xl mb-6">
                <h2 className="text-2xl font-bold mb-4">Payment Information Required</h2>
                <p className="mb-4">Please provide your payment details to complete the checkout process.</p>
                <Button onClick={() => {
                  toggleCart();
                  router.push(`checkout`)}} variant="primary" className="bold">
                  Checkout
                </Button>
                {error && <p className="text-red-500 mt-2">{error}</p>}
              </div>
              
            ) : (
              cart.length > 0 ? (
                <Checkout branding={{eventId:eventId}} isSidebar={true} onClose={toggleCart}/> 
              ) : (
                <div className="text-white text-xl"> Nothing to checkout</div>
              )
            )
        }
      </div>
      </div>
    </>
  );
};

export default PopupCart;
