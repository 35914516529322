"use client";
import React from 'react';
import { useCartStore } from "@/store/useCartStore";
import {
  PaymentElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { useParams } from "next/navigation";
import { toast } from "react-toastify";
import { Stripe, StripeElements } from "@stripe/stripe-js";

interface CheckoutFormProps {
  stripe: Stripe | null;
  elements: StripeElements | null;
  org: string;
  cart: any;
  totalPrice: number;
}

class CheckoutForm extends React.Component<CheckoutFormProps> {
  state = {
    isProcessing: false,
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { stripe, elements } = this.props;
    
    if (!stripe || !elements) {
      toast.error("Stripe hasn't loaded yet. Please try again.");
      return;
    }

    this.setState({ isProcessing: true });

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(submitError.message);
      }

      const response = await fetch("/api/v1/payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          orgId: this.props.org,
          discountCode: undefined,
          uiTotal: this.props.totalPrice,
          cart: {
            tickets: this.props.cart
              .filter((item: any) => item.type === "ticket")
              .map(({ id, quantity, event_id }: any) => ({ id, quantity, eventId: event_id })),
            products: this.props.cart
              .filter((item: any) => item.type === "product")
              .map(({ id, quantity }: any) => ({ id, quantity })),
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create payment intent");
      }

      const intent = await response.json();
      if (!intent.paymentIntentClientSecret) {
        throw new Error("Payment intent is missing client secret");
      }

      const { error: confirmError } = await stripe.confirmPayment({
        elements,
        clientSecret: intent.paymentIntentClientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/payment-confirmation`,
        },
      });

      if (confirmError) {
        throw new Error(confirmError.message);
      }

      toast.success("Payment successful");
    } catch (error) {
      console.error("Payment error:", error);
      toast.error(error instanceof Error ? error.message : "An unexpected error occurred");
    } finally {
      this.setState({ isProcessing: false });
    }
  };

  render() {
    const { stripe } = this.props;
    const { isProcessing } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="flex flex-col gap-4">
          <PaymentElement />
          <button
            type="submit"
            className="bg-green-primary px-2 py-1 rounded text-white"
            disabled={!stripe || isProcessing}
          >
            {isProcessing ? "Processing..." : "Pay Now"}
          </button>
        </div>
      </form>
    );
  }
}

export default CheckoutForm;